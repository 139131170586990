@font-face {
  font-family: "PlatNomor";
  src: local("PlatNomor"),
    url("./assets/fonts/PlatNomor.otf") format("opentype");
  font-weight: normal;
}

@font-face {
  font-family: "DMSans";
  src: local("DMSans"), url("./assets/fonts/DMSans.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Lato";
  src: local("Lato"), url("./assets/fonts/Lato.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url("./assets/fonts/Montserrat.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Raleway";
  src: local("Montserrat"),
    url("./assets/fonts/Raleway.ttf") format("truetype");
  font-weight: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Lato;
  background-color: #363636;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
